import { http, createConfig } from 'wagmi'
import { bsc, bscTestnet } from 'wagmi/chains'
import { injected, metaMask, safe, walletConnect } from 'wagmi/connectors'

const projectId = '75f0ab21a52cbff7e566bcc9be983646'

export const config = createConfig({
  chains: [bsc],
  connectors: [injected(), walletConnect({ projectId }),], 
  ssr: true,
  transports: {
    // [bsc.id]: http(),
   [bsc.id]: http()
  },
})