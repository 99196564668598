import { createPublicClient, http } from 'viem'
import { mainnet, polygon, base, bscTestnet, bsc } from 'viem/chains'
import Web3 from 'web3'

export const publicClient = createPublicClient({
    chain: bsc,
    transport: http()
})

const isProduct = 'local'

const PROVIDER_URL_BSC = 'https://bsc-rpc.publicnode.com'
const PROVIDER_URL_TESTBSC = 'https://bsc-testnet-rpc.publicnode.com'
const PROVIDER_URL_BASE = 'https://mainnet.base.org'
const PROVIDER_URL_POL = 'https://polygon.llamarpc.com'
const PROVIDER_URL_ETH = 'https://ethereum-rpc.publicnode.com'

export const web3ClientBsc = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_BSC))
export const web3ClientTestBsc = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_TESTBSC))
export const web3Client = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_ETH))
export const baseWeb3Client = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_BASE))
export const polWeb3Client = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_POL))

export const web3Clients = {
    56: web3ClientBsc,
    97: web3ClientTestBsc,
    1: web3Client,
    137: polWeb3Client,
    8453: baseWeb3Client
}

export const imageUrl = 'https://dogfun.shop/api/uploads/'
//  : 'https://shitlord-fun-backend-main.onrender.com/api/uploads/'

export const apiUrl = 'https://dogfun.shop'
//  : 'https://shitlord-fun-backend-main.onrender.com'

export const imageUploadUrl = 'https://dogfun.shop/'
//  : 'https://shitlord-fun-backend-main.onrender.com/'

export const ethPriceApiUrl = {
 1: 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD',
 56: 'https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD',
 97: 'https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD',
 8453: 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD',
 137: 'https://min-api.cryptocompare.com/data/price?fsym=POL&tsyms=USD',
}

// export const supportedChainIds = [bsc.id]
export const supportedChainIds = [bsc.id]

export const chainLogos = {
    1: '/eth.svg',
    56: '/bsc.svg',
    97: '/testbsc.svg',
    137: '/polygon.svg',
    8453: '/base.svg',
}

export const feeAmounts = {
    1: 0.0012,
    8453: 0.0012,
    56: 0.02,
    137: 7.13,
    97: 0.02,
}

export const initialEth = {
    1: 2.07,
    8453: 2.07,
    56: 56.25,
    137: 12558,
    97: 56.25,
}

export const coinNames = {
    1: 'ETH',
    8453: 'ETH',
    56: 'BNB',
    97: 'BNB',
    10: 'ETH',
    137: 'POL'
}

export const scanLinks = {
    8453: 'https://basescan.org/',
    1: 'https://etherscan.io/',
    56: 'https://bscscan.com/',
    97: 'https://testnet.bscscan.com/',
    137: 'https://polygonscan.com/',
    10: 'https://optimistic.etherscan.io/'
}

export const scanApiLinks = {
    8453: 'https://api.basescan.org/api',
    1: 'https://api.etherscan.io/api',
    56: 'https://api.bscscan.com/api',
    97: 'https://api-testnet.bscscan.com/api',
    137: 'https://api.polygonscan.com/api'
}

export const apiKeys = {
    56: 'Y7TXAF2H8KCPY7AXFT7DSTBFUVH794ZCST',
    97: 'Y7TXAF2H8KCPY7AXFT7DSTBFUVH794ZCST',
}

export const chainNames = {
    1: 'eth',
    8453: 'base',
    56: 'bnb',
    97: 'bnbTest',
    137: 'polygon'
}

export const chainNames1 = {
    1: 'ethereum',
    8453: 'base',
    56: 'bsc',
    97: 'bscTest',
    137: 'polygon'
}

export default function formatNumber(number) {
    if (number >= 1000000) {
        return (number / 1000000).toLocaleString() + 'M';
    } else if (number >= 1000) {
        return (number / 1000).toLocaleString() + 'K';
    } else {
        return number.toString();
    }
}