import React, { useEffect } from 'react'
import AllLaunches from './container/AllLaunches'
import CreateDogDefi from './container/CreateDogDefi.tsx'
import NotFound from './container/NotFound'
import BuyPage from './container/BuyPage'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { WagmiProvider } from 'wagmi'
import Profile from './container/Profile.tsx'
import EditProfile from './container/EditProfile'
// import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
// import { Web3Modal } from "@web3modal/react";
import toast, { ToastBar, Toaster } from 'react-hot-toast'
import './index.css'
import AboutUs from './container/AboutUs'
import Faq from './container/Faq'
import { bsc, bscTestnet } from 'wagmi/chains'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { config } from './config.jsx'

const projectId = '75f0ab21a52cbff7e566bcc9be983646'

const queryClient = new QueryClient()

const metadata = {
  name: 'Dogdefi',
  description: 'Dogdefi Wagmi',
  url: 'https://dogdefi.fun',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [bsc]

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

createWeb3Modal({
  themeVariables: {
    '--w3m-accent': '#F3CC2F',
    '--w3m-border-radius-master': '1px'
  },
  projectId,
  metadata,
  wagmiConfig
})

// const ethereumClient = new EthereumClient(wagmiConfig, chains);

const App = () => {
  return (
    <WagmiProvider reconnectOnMount config={config}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <QueryParamProvider>
            <div>
              <Toaster
                position="top-right"
                reverseOrder={true}
                toastOptions={{ duration: 5000 }}
              >
                {t => (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => toast.dismiss(t.id)}
                  >
                    <ToastBar onClick={() => alert(1)} toast={t} />
                  </div>
                )}
              </Toaster>
              <Switch>
                <Route exact path="/">
                  <AllLaunches />
                </Route>
                {/* <Route exact path="/MyContributions">
                <MyContributions />
              </Route> */}
                <Route exact path="/AllLaunches">
                  <AllLaunches />
                </Route>
                <Route exact path="/CreateDogDefi">
                  <CreateDogDefi />
                </Route>
                <Route exact path="/Buy">
                  <BuyPage />
                </Route>
                <Route exact path="/Profile">
                  <Profile />
                </Route>
                <Route exact path="/EditProfile">
                  <EditProfile />
                </Route>
                <Route exact path="/about-us">
                  <AboutUs />
                </Route>
                <Route exact path="/FAQ">
                  <Faq />
                </Route>
                <Route exact path="/NotFound">
                  <NotFound />
                </Route>
              </Switch>
              {/* <Web3Modal
            projectId={projectId}
            ethereumClient={ethereumClient}
          /> */}
            </div>
          </QueryParamProvider>
        </Router>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export default App
